<template>
  <div class="visit-replay-detail-edit">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ data.title }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">
          授信等级：{{ data.level }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
        <van-col span="8" @click="to(data.lineDetail)">航线明细
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16" @click="to2(info.crmCustomerid)">货量详情跳转
          <van-icon name="share-o"/>
        </van-col>
        <van-col span="8" @click="to1(data.problermDetail)">成果方案
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
    </div>
    <div style="background-color: #FFFFFF">
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="editPlan()">
        <van-field required autosize label="计划日期" readonly :rules="customerForm.target" placeholder="计划日期"
                   v-model="info.plandateStart +'  至  '+ info.plandateEnd" label-width="6.5em"></van-field>
        <van-field required v-if="info.target" autosize type="textarea" label="目标" readonly :rules="customerForm.target"
                   placeholder="目标"
                   v-model="info.target === 'null'?'':info.target"></van-field>
        <van-field required v-if="info.situation" autosize type="textarea" label="现状" readonly
                   :rules="customerForm.situation"
                   placeholder="现状"
                   v-model="info.situation === 'null'?'':info.situation"></van-field>
        <van-field required v-if="info.plan" autosize type="textarea" label="计划方案" readonly :rules="customerForm.plan"
                   placeholder="计划方案"
                   v-model="info.plan === 'null'?'':info.plan"></van-field>
        <van-row v-if="info.interviewType">
          <van-field
              readonly
              required
              clickable
              label="走访类型："
              input-align="left"
              :value="info.interviewType"
              :rules="customerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
              label-width="6.5em"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field required v-if="info.goalPlan" autosize type="textarea" label="目标及方案" readonly placeholder="请填写目标及方案"
                   v-model="info.goalPlan === 'null'?'':info.goalPlan" label-width="6.5em"></van-field>
        <van-field required label="实际联系人" :rules="customerForm.realContact" placeholder="请填写实际联系人"
                   v-model="info.realContact === 'null'?'':info.realContact" label-width="6.5em"></van-field>
        <van-field :required="true" label="业务推进成果" :rules="customerForm.pushResult" placeholder="请填写业务推进成果"
                   @click-input="clickItem(info.pushResult, '业务推进成果', false)"
                   v-model="info.pushResult === 'null'?'':info.pushResult" label-width="6.5em"></van-field>
        <van-field  v-if="info.comOperate" :required="true" label="综合经营情况" :rules="customerForm.comOperate" placeholder="业务量、应收情况、人员等方面"
                   @click-input="clickItem(info.comOperate, '综合经营情况', false)"
                   v-model="info.comOperate === 'null'?'':info.comOperate" label-width="6.5em"></van-field>
        <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
        <van-field required label="业务量" :rules="customerForm.businessVolume" placeholder="业务量"
                   @click-input="clickItem(info.businessVolume, '业务量', false)"
                   v-model="info.businessVolume === 'null'?'':info.businessVolume" label-width="6.5em" class="lable"></van-field>
        <van-field required label="盈利情况" :rules="customerForm.profitSituation" placeholder="应收情况"
                   @click-input="clickItem(info.profitSituation, '盈利情况', false)"
                   v-model="info.profitSituation === 'null'?'':info.profitSituation" label-width="6.5em" class="lable"></van-field>
        <van-field label="其他" placeholder="人员等方面"
                   @click-input="clickItem(info.others, '其他', false)"
                   v-model="info.others === 'null'?'':info.others" label-width="6.5em" class="lable"></van-field>
        <div v-if="listCf.length>0 || (info.enterpriseType == '船方' && info.enterpriseNature == '船东')">
          <van-row>
            <van-col span="16">
              <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
            </van-col>
            <van-col v-if="info.status=='未批复'" span="8" style="color: #289AE1; font-size: 14px; padding-top: 8px">
              <span  @click="showModal = true">新建船舶＋</span>
            </van-col>
          </van-row>
          <van-row v-for="(item,index) in listCf">
            <van-col span="21">
            <van-field required :label="item.shipName" placeholder="请填写船舶运营情况"
                       :rules="customerForm.shipOperation"
                       @click-input="clickItem(item.shipOperation, item.shipName, false)"
                       v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
            </van-col>
            <van-col span="3" style="margin-top: 15px" @click="deleteOne(item)">
              <van-icon :name="require('@/assets/删减.png')"/>
            </van-col>
          </van-row>
        </div>
        <van-row>
          <van-col span="20">
            <van-field required autosize type="textarea" label="遗留问题" :rules="customerForm.problerms" placeholder="遗留问题"
                       :readonly=checked label-width="6.5em"
                       v-model="info.problerms">
            </van-field>
          </van-col>
          <van-col span="4">
            <van-col offset="1" span="23" class="text info">
              填/不填
            </van-col>
            <van-switch :value="checked" @input="onInput" size="20px" style="text-align:center;margin: 0px auto;"/>
          </van-col>
        </van-row>
        <van-row>
          <van-collapse v-model="activeNames" style="text-align: left">
            <van-collapse-item title="选择新增客户(非必选)" name="1">建议保存后添加客户，以免所填信息遗失
              <template #right-icon>
                <van-switch :value="check" @click="clickInput" size="20px" style="text-align:center;margin: 0px auto;"/>
              </template>
            </van-collapse-item>
          </van-collapse>
        </van-row>

        <van-field autosize :readonly=readonly v-if="!readonly" type="textarea" label="领导评价" placeholder="领导评价"
                   style="height: 150px"
                   v-model="info.official" label-width="6.5em"></van-field>
<!--        <van-button v-if="isHidden" type="primary" size="large" native-type="submit">保存-->
<!--        </van-button>-->
        <van-button type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>

      <!--填写船舶信息-->
      <div>
        <Modal :visible="showModal" @close="showModal = false" ref="taskModal"
               id="taskModal1">
          <van-form @submit="saveVessel()">
            <van-field required label="船舶公司：" readonly placeholder="请输入船舶公司" label-width="6.5em"
                       v-model="shipInfo.vesselCompany"></van-field>
            <van-field required label="船舶名称：" placeholder="请输入船名" label-width="6.5em" :rules="customerForm.vesselName"
                       v-model="shipInfo.vesselName"></van-field>
            <van-field label="租船人：" placeholder="请填写租船人" label-width="6.5em"
                       v-model="shipInfo.charterer"></van-field>
            <van-field label="船东信息：" placeholder="请填写船东信息" label-width="6.5em"
                       v-model="shipInfo.customerName"></van-field>
            <van-field label="船东联系人：" placeholder="请填写船东联系人" label-width="6.5em"
                       v-model="shipInfo.contacts"></van-field>
            <van-field label="载重吨：" placeholder="请填写数字" label-width="6.5em" type="number"
                       v-model="shipInfo.dwt"></van-field>
            <van-field label="船长：" placeholder="请填写数字" label-width="6.5em" type="number"
                       v-model="shipInfo.length"></van-field>
            <van-field label="满载吃水：" placeholder="请填写数字" label-width="6.5em" type="number"
                       v-model="shipInfo.draught"></van-field>
            <van-field label="舱口数：" placeholder="请填写数字" label-width="6.5em" type="number"
                       v-model="shipInfo.hatch"></van-field>
            <van-field label="建造年限：" placeholder="请填写建造年限" label-width="6.5em"
                       v-model="shipInfo.period"></van-field>
            <van-field label="主要航线：" placeholder="请填写主要航线" label-width="6.5em"
                       v-model="shipInfo.mainRoutes"></van-field>
            <van-field  label="船舶种类：" placeholder="请填写船舶种类" label-width="6.5em"
                        v-model="shipInfo.vesselType"></van-field>
            <van-field label="主要货种：" placeholder="请填写主要货种" label-width="6.5em"
                       v-model="shipInfo.mainGoods"></van-field>
            <!--              <van-field label="总舱容：" placeholder="请填写总舱容" label-width="6.5em"-->
            <!--                         v-model="shipInfo.toalCapacity"></van-field>-->
            <div class="submit">
              <van-button type="default" native-type="button"
                          @click="showModal=false, vesselInfo ={}">取消
              </van-button>
              <van-button type="info" native-type="submit">确定</van-button>
            </div>
          </van-form>
        </Modal>

      </div>

      <!--删除船舶-->
      <van-dialog
          v-model="giveup"
          :title=title
          show-cancel-button
          :beforeClose="giveupBtn"
          confirm-button-text='只删除记录'
          cancel-button-text='删除记录和船舶'
      >
      </van-dialog>

      <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show" @success="success" @editVal="editVal"></dialog-card>
    </div>
    <crm-tab-bar :tabpage="3"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import DialogCard from "../../components/DigitalCell/DialogCard";
import {deleteBulkShipinfotwo, saveBulkShipinfotwo, deleteCfDetail} from "../../api/shipInfo";
import {saveCfDetailByReplay} from "../../api/replay";
import {Toast} from "vant";
import Modal from './../../components/Modal/MyCustomerModal';

export default {
  name: "BulkCrmsVisitReplayDetailEdit",
  components: {CrmTabBar, DialogCard, Modal},
  data() {
    return {
      title: '请选择删除情况',
      giveup: false,
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      vessel: [],
      showModal: false,
      show: false,
      fieldValue: '',
      DialogData: {},
      checked: false,
      isHidden: false,
      showPicker: false,
      readonly: true,
      maxDate: new Date(2030, 1, 1),
      currentDate: new Date(),
      ifRequired: false,
      data: {
        title: '',
        level: '',
        weight: '',
        benefit: '',
        lineDetail: '',
        planDetail: 'BulkCrmsVisitReplayDetail',
        problermDetail: ''
      },
      info: {},
      list: [],
      listCf: [],
      loading: false,
      customerForm: {
        customerName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realContact: [
          {required: true, message: '', trigger: 'blur'},
        ],
        pushResult: [
          {required: true, message: '', trigger: 'blur'},
        ],
        comOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        otherDemand: [
          {required: true, message: '', trigger: 'blur'},
        ],
        situation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        result: [
          {required: true, message: '', trigger: 'blur'},
        ],
        problerms: [
          {required: true, message: '', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        businessVolume: [
          {required: true, message: '', trigger: 'blur'},
        ],
        profitSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        others: [
          {required: true, message: '', trigger: 'blur'},
        ],
        shipOperation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        vesselName: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      activeNames: [],
      check: false,
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      showInterviewTypePicker: false,
      deleteOption: '',
      deleteId: ''
    }
  },
  watch: {
    // 'ifRequired': function () {
    //   this.customerForm.pushResult[0].required = this.ifRequired
    //   this.customerForm.comOperate[0].required = !this.ifRequired
    // },
  },
  mounted() {
    this.init()
  },
  methods: {
    to(to) {
      console.log(to)
      if (to.length == 0) {
        this.$message.warning('暂无航线明细！');
      } else {
        this.$router.push(to)
      }
    },
    to1(to) {
      console.log(to)
      if (to.length == 0) {
        this.$message.warning('暂无成果方案！');
      } else {
        this.$router.push(to)
      }
    },
    to2(to) {
      console.log(to)
      this.$router.push("/CustomerPayerTo/" + to)
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    onConfirm(time) {
      this.info.date = this.formatter(time);
      this.showPicker = false;
    },
    editPlan(formName) {
      this.$ajax.post('/api/ajax/replay/updateBulkCrmReplay.json',
          {
            customerName: this.info.customerName,
            replayId: this.info.replayId,
            crmCustomerid: this.info.crmCustomerid,
            travelType: this.info.travelType,
            titleName: this.info.titleName,
            titleId: this.info.titleId,
            travelDate: this.info.travelDate,
            plandateStart: this.info.plandateStart,
            plandateEnd: this.info.plandateEnd,
            target: this.info.target,
            situation: this.info.situation,
            plan: this.info.plan,
            result: this.info.result,
            realContact: this.info.realContact,
            realOperate: this.info.realOperate,
            otherDemand: this.info.otherDemand,
            pushResult: this.info.pushResult,
            comOperate: this.info.comOperate,
            problerms: this.info.problerms,
            remarks: this.info.remarks,
            businessVolume: this.info.businessVolume,
            profitSituation: this.info.profitSituation,
            others: this.info.others,
            modifiedPeople: this.$store.getters.userId,
            bulkCrmCfDetailDTO: this.listCf
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('更新成功！');
          setTimeout(() => {
            if (this.check) {
              this.$router.replace({path: '/CustomerListAdd/' + 'replay' + Math.random().toString(36).slice(2)})
            } else {
              this.$router.replace({path: '/BulkCrmsVisitReplayEdit/' + this.info.titleId})
            }
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('更新失败！');
      })
    },
    init() {
      this.$ajax.post('/api/ajax/replay/getBulkCrmReplayList.json',
          {
            replayId: this.$route.params.id,
            titleId: '',
            titleName: '',
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          console.log(1)
          if (res.data.length > 0) {
            this.info = res.data[0]
            if (this.info.interviewType == '业务类拜访') {
              this.ifRequired = true
            }
            this.shipInfo.vesselCompany=res.data[0].customerName
            this.data.title = res.data[0].customerName
            this.data.lineDetail = '/LineDetail/' + res.data[0].customerName
            this.data.problermDetail = '/RemainProblermsDetail/' + res.data[0].titleId + '/' + res.data[0].crmCustomerid
            for (let i = 0; i < this.info.length; i++) {
              this.info[i].to = '/BulkCrmsVisitReplayList/' + this.info[i].travelPlanid
            }
            if(res.data[0].bulkCrmCfDetailDTO.length>0){
              this.listCf = res.data[0].bulkCrmCfDetailDTO
            }
          }
          if (this.info.status == '未批复') {
            this.isHidden = true;
          }
          if (this.$store.getters.userId == '05001') {
            this.readonly = false
          }
          //获取客户信息
          this.$ajax.post('/api/ajax/bulkCrmsCusList/getHlxy.json',
              {
                customerName: res.data[0].customerName
              }).then(res => {
            if (res.status == 200) {
              if (res.data.length > 0) {
                this.data.weight = res.data[0].hfDTO.weight + "万吨"
                this.data.benefit = res.data[0].hfDTO.profit + "万元"
                this.data.level = res.data[0].customerLevels
              }
              console.log(this.data)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('获取客户货量效益失败！');
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })

    },
    onInput(checked) {
      console.log(checked)
      this.checked = checked;
      if (this.checked) {
        this.info.problerms = '无'
      } else {
        this.info.problerms = ''
      }
    },
    clickInput() {
      this.check = !this.check;
    },
    onClickType() {
      this.showInterviewTypePicker = true
      // this.data.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      this.data.interviewType = picker;
      this.showInterviewTypePicker = false;
    },
    clickItem(val, text, bool){
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },
    success(res){
      this.show = res
    },
    editVal(val){
      if(val.text == '业务推进成果'){
        this.info.pushResult = val.value
      }else if(val.text == '综合经营情况'){
        this.info.comOperate = val.value
      }else if(val.text == '业务量'){
        this.info.businessVolume = val.value
      }else if(val.text == '盈利情况'){
        this.info.profitSituation = val.value
      }else if(val.text == '其他'){
        this.info.others = val.value
      }else{
        if(this.listCf.length>0){
          for(let i=0; i<this.listCf.length; i++){
            if(val.text == this.listCf[i].shipName){
              this.listCf[i].shipOperation = val.value
            }
          }
        }
      }
    },

    saveVessel(){
      saveBulkShipinfotwo({
        shipInformationid: this.info.crmCustomerid,
        vesselName: this.shipInfo.vesselName,
        charterer: this.shipInfo.charterer,
        vesselCompany: this.shipInfo.vesselCompany,
        cooperationTimes: this.shipInfo.cooperationTimes,
        dwt: this.shipInfo.dwt,
        length: this.shipInfo.length,
        draught: this.shipInfo.draught,
        hatch: this.shipInfo.hatch,
        period: this.shipInfo.period,
        mainRoutes: this.shipInfo.mainRoutes,
        vesselType: this.shipInfo.vesselType,
        mainGoods: this.shipInfo.mainGoods,
        toalCapacity: this.shipInfo.toalCapacity,
        capacity: this.shipInfo.capacity,
        createdPeople: this.$store.getters.userId,
      }).then(res => {
        saveCfDetailByReplay({
          titleId: this.info.titleId,
          titleName: this.info.titleName,
          replayId: this.info.replayId,
          customerName: this.info.customerName,
          crmCustomerid: this.info.crmCustomerid,
          travelPlanid: this.listCf.length>0? this.listCf[0].travelPlanid: '',
          shipName: this.shipInfo.vesselName,
          createdPeople: this.$store.getters.userId,
        }).then(res1 => {
          res1.vesselId = res.vesselId
          this.listCf.push(res1)
          this.clearShip()
        }).catch(err => {
          console.log(err)
        });
        Toast.success('添加船舶成功！');
      }).catch(err => {
        console.log(err)
      });
    },
    clearShip(){
      this.showModal = false
      this.shipInfo = {
        vesselCompany: this.data.title,
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      }
    },
    deleteOne(e){
      this.deleteOption = e.vesselId
      this.deleteId = e.id
      let index = this.listCf.findIndex(item => {
        if (item.shipName == e.shipName) {
          return true
        }
      })
      if(e.remarks=='新增'){
        this.giveup = true
      }else{
      }
      this.listCf.splice(index, 1)
    },
    giveupBtn(action, done) {
      deleteCfDetail({
        id: this.deleteId
      }).then(res => {
      }).catch(err => {
        console.log(err)
      });
      if (action === 'confirm') {
        done()
      } else if (action === 'cancel') {
        deleteBulkShipinfotwo({
          vesselId: this.deleteOption
        }).then(res => {
        }).catch(err => {
          console.log(err)
        });
        done()
      }
    },
  }
}
</script>

<style scoped>

.visit-replay-detail-edit .lable{
  margin-left: 15px;
  font-size: 12px;
}

.visit-replay-detail-edit {
  height: 1500px;
  overflow-y: scroll
}

.visit-replay-detail-edit .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.visit-replay-detail-edit .info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.visit-replay-detail-edit .card {
  background: #318EF5;
}

.visit-replay-detail-edit .info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.submit .van-button {
  width: 150px;
}
</style>

<style>
.visit-replay-detail-edit .van-collapse-item__content {
  padding: 12px 16px;
  color: red;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}
</style>
