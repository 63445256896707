<template>
  <transition name="fade">
    <div class="modal" v-if="visible"  @click="closeModal">
      <div class="modal-content"  @click.stop>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MyCustomerModal",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal(event) {
      // 判断点击事件是否来自于modal-content以外的区域
      if (event.target === this.$el) {
        this.$emit('close');
      }
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%; /* 设置弹窗的最大宽度 */
  min-width: 80%; /* 设置弹窗的最大宽度 */
  max-height: 90%; /* 设置弹窗的最大宽度 */
  min-height: 30%; /* 设置弹窗的最大宽度 */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>