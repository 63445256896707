import request from '@/utils/request'

export function saveBulkShipinfotwo(formFile) {
    return request({
        url: "/api/ajax/bulkCrmShipinfo/saveBulkShipinfotwo.json",
        method: 'post',
        data: formFile,
    })
}

export function getBulkCrmShipinfo(formFile) {
    return request({
        url: "/api/ajax/bulkCrmShipinfo/getBulkCrmShipinfo.json",
        method: 'post',
        data: formFile,
    })
}

export function deleteBulkShipinfotwo(formFile) {
    return request({
        url: "/api/ajax/bulkCrmShipinfo/deleteBulkShipinfotwo.json",
        method: 'post',
        data: formFile,
    })
}

export function deleteCfDetail(formFile) {
    return request({
        url: "/api/ajax/replay/deleteCfDetail.json",
        method: 'post',
        data: formFile,
    })
}

export function addHfVessel(formFile) {
    return request({
        url: "/api/ajax/planVisit/addHfVessel.json",
        method: 'post',
        data: formFile,
    })
}
